import Link from 'next/link';
import { ReactNode } from 'react';

interface MarketingFooterProps {
  background?: 'lupine' | 'white';
}

export const MarketingFooter: React.FC<MarketingFooterProps> = ({ background = 'lupine' }) => {
  const bgClass = background === 'lupine' ? 'bg-lupine-50' : 'bg-white';

  return (
    <footer className={bgClass}>
      <div className="border-b border-lupine-200" />
      <div className="max-w-5xl py-10 px-4 md:py-20 mx-auto flex flex-col md:flex-row md:justify-between items-center md:items-start gap-8">
        <p className="flex items-center">
          <span className="font-sans text-lupine-950 text-sm">
            Copyright © {new Date().getFullYear()} Wolfia
          </span>
        </p>
        <ul className="flex flex-row w-full md:w-auto justify-around md:gap-20">
          <li>
            <h3 className="font-sans text-sm font-medium mb-6 text-lupine-950 text-center md:text-left">
              Explore
            </h3>
            <ul className="flex flex-col gap-6 items-center md:items-start">
              <FooterNavLink href="/">Home</FooterNavLink>
              <FooterNavLink href="/about">About</FooterNavLink>
            </ul>
          </li>
          <li>
            <h3 className="font-sans text-sm font-medium mb-6 text-lupine-950 text-center md:text-left">
              Resources
            </h3>
            <ul className="flex flex-col gap-6 items-center md:items-start">
              <FooterNavLink href="/blog">Blog</FooterNavLink>
              <FooterNavLink href="/case-studies">Case studies</FooterNavLink>
              <FooterNavLink href="/terms">Terms of service</FooterNavLink>
              <FooterNavLink href="/privacy">Privacy policy</FooterNavLink>
            </ul>
          </li>
          <li>
            <h3 className="font-sans text-sm font-medium mb-6 text-lupine-950 text-center md:text-left">
              Follow us
            </h3>
            <ul className="flex flex-col gap-6 items-center md:items-start">
              <FooterNavLink
                href="https://twitter.com/wolfia_app"
                target="_blank"
              >
                Twitter
              </FooterNavLink>
              <FooterNavLink
                href="https://www.linkedin.com/company/wolfia-app"
                target="_blank"
              >
                LinkedIn
              </FooterNavLink>
            </ul>
          </li>
        </ul>
      </div>
    </footer>
  );
};

const FooterNavLink = ({
  href,
  children,
  target,
}: {
  href: string;
  children: ReactNode;
  target?: '_blank';
}) => {
  return (
    <li className="w-full">
      <Link
        href={href}
        className="block font-sans text-sm text-lupine-900 font-medium opacity-60 hover:opacity-100 transition-[opacity] p-3 -m-3 rounded focus-visible:outline outline-2 outline-fog-50 hover:underline"
        target={target}
      >
        {children}
      </Link>
    </li>
  );
};
